import { I18n } from 'i18n-js'
import translations from './translations.json' // eslint-disable-line import/no-unresolved

const i18n = new I18n(translations)

i18n.defaultLocale = 'en'
i18n.enableFallback = true
i18n.locale = document.documentElement.getAttribute('lang')

window.I18n = i18n
